import './App.css';
import Project from './Project.js'
import { useRef } from 'react';

function App() {

const projectsTitle = useRef(null);
const contactMe = useRef(null);

const scrollToSection = (elementRef) => {
  window.scrollTo({
    top: elementRef.current.offsetTop,
    behavior: "smooth",
   });
  };
  

  return (
    <div className="App">
      <header className="App-header">
        <p className="IntroText">
          Hello!<span className="blog-title-emoji">👋</span>
        </p>
<br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        <p className="name">I'm <b>Sindusara Munasinghe</b>, a Computer Engineer studying at Queen's.</p>
        <div>
          <button className="landing-button" onClick={() => scrollToSection(projectsTitle)}>See My Work</button><button className="landing-button" onClick={() => window.open("https://drive.google.com/file/d/15rrL3BIHzSkRcIRTbhRzyHuTYyRgA_E0/view?usp=sharing")}>View My Resume</button><button className="landing-button" onClick={() => scrollToSection(contactMe)}>Let's Connect</button>
        </div>
      </header>

      <header ref={projectsTitle} className="projects">
        <br></br><h1>Projects</h1>
        <p>I have been creating projects throughout my development journey because of my <b>passion for creation.</b><br></br>Let me give you the highlights.</p>
        <Project url="https://img.itch.zone/aW1hZ2UvMTk3OTM2MC8xMTY0MDQ3MC5wbmc=/original/CHEeu%2B.png" title="Befriend!!! with Sindu, Alex, Sean, and Thomas!" info="Web Game | HTML, CSS, Javascript" desc="A game I created in 24 hours in a team of four friends for the YRHACKS hackathon. Developed the main game functions and code. 'Befriend!!!' won third place overall."/>
        <Project url="https://i.imgur.com/M96I09K.png" title="Game Development" info="Windows-Based Games | C#, Monogame, Godot, Unity, Javascript, HTML, CSS, GDScript" desc="I've been solo developing video games in my free time as a passion project for over two years now. The games I've made span over many different languages, frameworks, and engines, however I am currently working on C# games using the Monogame framework."/>
        <Project url="https://i.imgur.com/KeBdUPs.png" title="LAST.FM Higher or Lower" info="Web Game | APIs, HTML, CSS, Javascript" desc="A web game incorporating LAST.FM's database and API to fetch user data and turn it into an interactable game."/>

      </header>

      <header ref={contactMe} className='contactMe'> 
      <h1>Contact Me</h1><br></br>
      <div className='flexbox'>
      <button className='contactButton' onClick={() => window.open("mailto:sindusaram@gmail.com")}>Email</button>
      <button className='contactButton' onClick={() => window.open("https://www.linkedin.com/in/sindusara-munasinghe/")}>LinkedIn</button>
      <button className='contactButton' onClick={() => window.open("https://github.com/sinndu")}>Github</button>
      </div>
      </header>
    </div>
  );
}

export default App;
