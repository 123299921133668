import "./Project.css";
import lastfm from "./lastfm.png";

function Project(props){
    return(
        <div class="project">
            <div className="bg" style={{backgroundImage: 
                "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 255)), url(" + props.url + ")"}}>
                <h1>{props.title}</h1>
                <p><i>{props.info}</i></p>
                <p>{props.desc}</p>
                </div>
                <br></br>
        </div>
    )
}

export default Project